if ($(window).width() >= 576) {
        $(window).scroll(function(){ 
        var navHeight = $('.navbar-default').height();
        if ($(window).scrollTop() >= $('.header').height()) {
            $('.navbar-default').addClass('navbar-fixed');
            $('.header').css({'marginTop': navHeight + 'px'}); 
        } else {
            $('.navbar-default').removeClass('navbar-fixed');
            $('.header').css({'marginTop': '0px'});
        }

    });
}

if ($(window).width() <= 575) {

    $(window).on('load', function () {
        $('.header .logo').delay(800).fadeOut(100);
        $('.header .triangle-mobile').delay(800).fadeOut(100);
        $('.header .lady-collage').delay(800).fadeOut(100);
        $('.header').delay(1000).fadeOut(200);
        setTimeout(function(){
            $('body').css('overflow','initial');
        },1000);
    });

    $('.partners-slider__sm').bxSlider({
        pager: false,
        controls: true
    });

    $('.news-slider').bxSlider({
        pager: false,
        controls: false
    });

    $('.projects').on('click', '.projects-poster img' ,function(e){
        $(this).next('.projects-info__sm').fadeIn();
    })

    $('.projects').on('click', '.projects-info__sm' ,function(e){
        $(this).fadeOut();
    });
}

// Шедевральная функция "активное меню"
let navbarItems = document.querySelectorAll('.navbar-path a');
let path = window.location.pathname;
for (var i = 0; i < navbarItems.length; i++) {
    if (navbarItems[i].getAttribute('href') == path) {
        navbarItems[i].parentNode.classList.add('active');
        // if (navbarItems[i].parentNode.classList.contains('nav-sub__item')) {
        //     document.querySelector('.navbar-submenu').classList.add('active');
        // }
    }
}

$(window).resize(function(e){

    

    $('.navbar-submenu__content.opened').css({
        'width': $(window).width()+'px',
        'height': $(window).height()+'px',
    })
});

function openMenu(diagonal) {
    $('.navbar-default').css({'z-index':'2050'});
	$('.navbar-submenu__content').animate({
  		'width': diagonal * 2 + 'px',
  		'height': diagonal * 2 + 'px',
  		'marginRight': -diagonal + 'px',
  		'marginTop': -diagonal + 'px',
	},
	550, 
	function() {
		$('.navbar-submenu__content').css({
			'width': $(window).width()+'px',
            'height': $(window).height()+'px',
           	'marginRight': '0',
            'marginTop': '0',
            'borderRadius': '0',
		});

        // $('.wrapper').addClass('no-scroll');

        $('.navbar-submenu__container').fadeIn(200);
        $('.navbar-submenu__content').addClass('opened');
        
	});
}

function closeMenu(diagonal) {

    // $('.wrapper').removeClass('no-scroll');

    $('.navbar-default').css({'z-index':'2048'});

    $('.navbar-submenu__container').fadeOut(200);

    $('.navbar-submenu__content').removeClass('opened');

    setTimeout(function(){
        $('.navbar-submenu__content').css({
            'width': diagonal * 2 + 'px',
            'height': diagonal * 2 + 'px',
            'marginRight': -diagonal + 'px',
            'marginTop': -diagonal + 'px',
            'borderRadius': '50%'
        });

        $('.navbar-submenu__content').animate({
            'width': '0px',
            'height': '0px',
            'marginRight': '0px',
            'marginTop': '0px',
        },
        550);
    },200);

	
}

$('.navbar-submenu > a').click(function(e) {
	e.preventDefault();

	var diagonal = Math.round(Math.sqrt(Math.pow(window.innerWidth,2) + Math.pow(window.innerHeight,2)));

	if (!$(this).hasClass('open')) {
		$(this).addClass("open"); 
        $('body').css({'overflow':'hidden'});
		openMenu(diagonal);
	}else {
		$(this).removeClass("open"); 
        $('body').css({'overflow':'auto'});
		closeMenu(diagonal);
	}

	return false;
});

$('.toggle').click(function(e){
    e.preventDefault();

    $('#'+$(this).attr('data-target')).slideToggle(200);

    $('.toggle').find('img').toggleClass('inverse');

    return false;
});

$('.navbar-toggler').click(function(e){
    e.preventDefault();

    $('#'+$(this).attr('data-target')).slideToggle(200);

    // $('body').toggleClass('overflow');

    return false;
});

$('.nav-tabs a').click(function(e) {
    e.preventDefault();

    $('.nav-tabs a').parent().removeClass('active');
    $(this).parent().addClass('active');

    $('.tabs-content__item').removeClass('active');
    $('.tabs-content__item'+$(this).attr('href')).addClass('active');

    return false;
});

$('.toggle-input input').click(function() {
    console.log($(this).attr('data-target'));

    $('.toggle-input-elem').fadeOut(0);
    $('.toggle-input-elem#'+$(this).attr('data-target')).fadeIn(0);

});



$('a.megaslider-back').click(function(e){
    e.preventDefault();

    if(!$(this).hasClass('open'))
    {
        $(this).addClass('open');

        $('.megaslider-spec').animate({
            right: '0',
        });

    }else 
    {
        $(this).removeClass('open');

        $('.megaslider-spec').animate({
            right: '-500px',
        });
    }

    return false;
});

$('#agreement').click(function(){

    if ($(this).prop('checked')) {
        $('.btn[type="submit"]').prop('disabled', false);
    } else {
        $('.btn[type="submit"]').prop('disabled', true);
    }

});


$('#map-cinema .iframe-overlay').click(function(){
    $(this).fadeOut();
});
$('#map-cinema').mouseleave(function() {
    $('#map-cinema .iframe-overlay').fadeIn();
});

// Здесь ajax подгрузка кинотеатров
$('.form-default').on('click', 'input[data-target="cinema"].form-radio-input', function() {
    $.ajax({
        url: "/api/v1/ajax/getOrganization",
        type: "POST",
        data: {lang:$("input[name='lang']").val()},
        cache: false,
        beforeSend: function() {
            $('select.form-control option').remove();
        },
        success: function(response){

            $.each(response, function( index, value ) {
               $('select[name="CityName"]').append('<option value="'+value+'">'+value+'</option>');
            });
        }
    });
});

$('.form-default').on('change', 'select[name="CityName"]', function() {

    $.ajax({
        url: "/api/v1/ajax/getTheaters",
        type: "POST",
        data: {"city": $(this).val(), lang:$("input[name='lang']").val()},
        cache: false,
        beforeSend: function() {
            $('select[name="TheaterName"] option').remove();
        },
        success: function(response){

            $.each(response, function( index, value ) {
               $('select[name="TheaterName"]').append('<option value="'+value+'">'+value+'</option>');
            });
        }
    });
});

// Галлерея для Мегаслайдера
var slider = null;

$('.overlay-open').click(function(e) {
    e.preventDefault();

    $('body').css({'overflow':'hidden'});

    var elem = $(this).parents('.megaslider-photos-preview');

    var start = $(this).parent().index()

    $('.overlay').fadeIn(100);

    elem.clone().appendTo('.overlay');

    $('.overlay .megaslider-photos-preview').addClass('gallery').removeClass('megaslider-photos-preview');

    slider = $('.overlay .gallery').bxSlider({
        startSlide: start,
        pager: false
    });

    return false;
});

$('.overlay-close').click(function(e) {
    e.preventDefault();

    $('body').css({'overflow':'auto'});

    if (slider.length != 0) {
        slider.destroySlider();
    }

    $('.gallery').remove();

    $('.overlay').fadeOut(100);

    return false;
});

// Галерея для страницы галерея
$('.gallery-open').click(function(e) {

    e.preventDefault();

    $('body').css({'overflow':'hidden'});

    let target = $(this).attr('data-target');

    

    if ($(window).width() >= 768) {

        // $('.wrapper').addClass('no-scroll');
        
        $('.gallery-slides').fadeIn(500).css({
            opacity: 1,
        });
        $('.gallery-slider__wrapper').fadeIn(500);

        $('#'+target + ' img').clone().appendTo('#mCSB_1_container');
        $('.bx-pager img').wrap('<a data-slide-index="" href=""></a>');

        $('.bx-pager a').each(function(index, element){
            $(this).attr('data-slide-index', index);
        });

        $('#'+target).clone().appendTo('.gallery-slider__content');

        slider = $('.gallery-slider .gallery-slides').bxSlider({ 
            pager: true,
            pagerCustom: '#bx-pager',
            controls: true,
        });
    } else {

        let locale = $('html').attr('lang');
        $.ajax({
            url: '/'+locale+"/api/v1/ajax/getGallery",
            type: "POST",
            data: {collection:$(this).data('target'), boolean:$(this).data('boolean')},
            cache: false,
            beforeSend: function() {
                $('.gallery-slider .gallery-slides').remove();
            },
            success: function(response){
                $('.gallery-slider__content').append(response);
                $('.gallery-slides').fadeIn(500);
                $('.gallery-slider__wrapper').fadeIn(500);
            },
            complete: function() {

                // $('.wrapper').addClass('no-scroll');

                slider = $('.gallery-slider .gallery-slides').bxSlider({ 
                    pager: false,
                    controls: true,
                    preventDefaultSwipeX: false,
                    preventDefaultSwipeY: false,
                    touchEnabled: false,
                    oneToOneTouch: false,
                });
            }
        });

        
    }


    return false;

});

$('.gallery-close').click(function(e) {
    e.preventDefault();

    $('body').css({'overflow':'auto'});

    // $('.wrapper').removeClass('no-scroll');

    if (slider.length != 0) {
        slider.destroySlider();
    }

    $('.gallery-slider .gallery-slides').remove();
    $('.bx-pager a').remove();

    $('.gallery-slides').css({
        opacity: 0
    }).fadeOut(500);
    $('.gallery-slider__wrapper').fadeOut(500);

    return false;
});

$('.catalog').on('click', '.img-group img', function(e){
    e.preventDefault();

    $('body').css({'overflow':'hidden'});

    var elem = $(this).parents('.img-group');

    var start = $(this).index();

    $('.overlay').fadeIn(100);

    // $('.wrapper').addClass('no-scroll');

    elem.clone().appendTo('.overlay .photoviewer');

    $('.photoviewer img').wrap("<div class='slide'></div>");

    slider = $('.overlay .img-group').bxSlider({
        startSlide: start,
        pager: false
    });

    return false;
});

$('.photoviewer-close').click(function(e) {
    e.preventDefault();

    $('body').css({'overflow':'auto'});

    // $('.wrapper').removeClass('no-scroll');

    if (slider.length != 0) {
        slider.destroySlider();
    }

    $('.overlay .img-group').remove();

    $('.overlay').fadeOut(100);

    return false;
});


$('#contacts_us').submit(function(e) {
    e.preventDefault();

    var form = $(this).serializeArray();
    let locale = $('html').attr('lang');

    $.ajax({
        url: '/' + locale +'/ajax/form/sendmail',
        type: "POST",
        data: form,
        cache: false,
        success: function(response){
            
            if (response.status == 'error') {
                alert(response.text);
                var hash = Math.random() * 1000;
                $('.form-captcha img').removeAttr("src").attr('src', '/captcha?hash='+hash);
            }else {
                alert(response.text);
            }
        }
    });

    return false;
});

$('#share_voices').submit(function(e) {
    e.preventDefault();

    var form = $(this).serializeArray();

    $.ajax({
        url: $(this).attr('action'),
        type: "POST",
        data: form,
        cache: false,
        success: function(response){
            
            alert(response.text);
        }
    });

    return false;
});